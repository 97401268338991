<template>
    <v-form v-model="isValid">
        <v-row v-if="vehicle !== null">
            <v-col cols="12">
                <v-text-field v-if="false" outlined v-model="vehicle.name"
                              :label="$t('label.vehicleName')"
                              background-color="white"
                              />

                <v-text-field outlined v-model="vehicle.mobile" @change="changed"
                              :rules="[inputRules.required, inputRules.phoneNumber]"
                              background-color="white" type="tel"
                              :label="$t('label.vehicleMobile')"/>
                <v-text-field @change="changed" outlined v-model="vehicle.vehicle_license"
                              class="uppercase-input"
                              :label="$t('label.vehicleLicense')"
                              :rules="[inputRules.required]"
                              background-color="white"
                              required/>
                <v-text-field @change="changed" outlined v-if="!hasZones" v-model="vehicle.vehicle_colour"
                              background-color="white"
                              :label="$t('label.vehicleColour')" required/>
                <v-text-field @change="changed" outlined v-model="vehicle.vehicle_make_model"
                              :label="$t('label.vehicleMakeModel')"
                              :rules="[inputRules.required]"
                              background-color="white"
                              required/>
                <v-checkbox v-if="offerSave" @change="changed"
                            v-model="vehicle.save_vehicle"
                            :label="$t('label.rememberVehicle')" class="mt-n2"/>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

    export default {
        name: "VehicleEdit",
        data() {
            return {
                isValid: false,
                vehicle: null,
                zones: []
            }
        },
        methods: {
            changed() {
                this.$emit('input', this.vehicle);
            }
        },
        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('valid', newValue);
                }
            },
            value: {
                immediate: true,
                handler(newValue) {
                    if (newValue === null) {
                        return;
                    }
                    this.vehicle = {...this.defaultVehicle, ...newValue, name: this.clientName, save_vehicle: false, vehicle_license: newValue.vehicle_license.toUpperCase()};
                }
            },
        },
        props: {
            value: {type: Object},
            withoutName: {type: Boolean, default: false},
            offerSave: {type: Boolean, default: false},
            clientName: {type: String}
        },
        computed: {
            defaultVehicle() {
                return {
                    mobile: "",
                    name: "",
                    vehicle_colour: "",
                    vehicle_license: "",
                    vehicle_make_model: "",
                    save_vehicle: false,
                }
            },
            hasZones() {
                return this.zones.length > 0
            }
        }
    }
</script>

<style scoped>
    .uppercase-input >>> input {
        text-transform: uppercase;
    }
</style>
