<template>
    <v-row dense class="fill-height">
        <v-col cols="12" md="6" lg="8">

            <v-row dense>
                <v-col cols="12">
                    <p><a @click="goBack()" class="mb-8 secondary--text font-weight-bold"><v-icon class="mr-0">mdi-chevron-left</v-icon>{{ $t('btn.backToOrdering') }}</a></p>
                </v-col>
                <v-col cols="12" class="inbound-container">
                    <v-card class="elevation-0 transparent">
                        <v-card-title class="nolo-h3">{{ $t('curbsideTitle') }}</v-card-title>
                        <v-card-subtitle class="nolo-p2">{{ $t('curbsideLocation') }} {{ sessStore }}</v-card-subtitle>
                        <v-container>
                            <v-row dense class="pb-6">
                                <v-col cols="12">
                                <div>
                                    <div v-if="isLoggedIn && vehicles.length > 0" class="mb-8">
                                        <v-btn small depressed width="120" class="mr-4" color="yellow" @click="selectVehicle(vehicle)"
                                               v-for="vehicle in vehicles" :key="vehicle.vehicle_id">{{ vehicle.vehicle_license
                                            }}
                                        </v-btn>
                                    </div>
                                    <vehicle-edit v-if="sessVehicle !== null" v-model="sessVehicle" :clientName="clientName" @valid="valid => isValid = valid" :offer-save="isLoggedIn"/>
                                </div>
                                <v-btn block :disabled="!isValid" @click="curbsideContinue" color="primary">{{
                                    $t('btn.continue')}}
                                </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="hidden-sm-and-down pt-4">
            <shop-basket-summary :basket="basket" readonly :language="appLanguage" :currency="appCurrency"/>
        </v-col>
    </v-row>

</template>

<script>
    import CheckoutHelper from "../../mixins/CheckoutHelper";
    import VehicleEdit from "../Account/VehicleEdit";
    import ShopBasketSummary from "../Shop/ShopBasketSummary";

    export default {
        name: "Curbside",
        components: {VehicleEdit, ShopBasketSummary},
        mounted() {
            if (this.sessOrderType !== 'Curbside') {
                this.$router.replace({name: 'checkout-contact-store'})
            }
            if (this.sessVehicle === null) {
                if (this.isLoggedIn) {
                    this.sessVehicle = {
                        ...this.defaultVehicle,
                        name: this.appCustomer.firstName + ' ' + this.appCustomer.lastName,
                        mobile: this.appCustomer.phoneNumber
                    };
                } else {
                    this.sessVehicle = {
                        ...this.defaultVehicle,
                        name: this.sessGuest.firstName + ' ' + this.sessGuest.lastName,
                        mobile: this.sessGuest.phoneNumber
                    };
                }
            }
        },
        data() {
            return {
                defaultVehicle: {
                    "mobile": "",
                    "name": "",
                    "vehicle_colour": "",
                    "vehicle_license": "",
                    "vehicle_make_model": ""
                },
                isValid: false
            }
        },
        methods: {
            curbsideContinue() {
                // this.$store.commit('session/guest', this.guest);
                // this.$store.commit('session/guestMarketing', this.marketing);
                this.checkoutNextRoute(false);
            },
            selectVehicle(vehicle) {
                this.sessVehicle = {...vehicle}
            },
            goBack() {
                this.$router.push({name: 'order'});
            }
        },
        mixins: [
            CheckoutHelper
        ],
        computed: {
            appCustomer() {
                return this.$store.getters['session/customer'];
            },
            vehicles() {
                return this.$store.getters['session/customer'].vehicles;
            },
            sessVehicle: {
                set(newValue) {
                    this.$store.commit('session/vehicle', newValue);
                },
                get() {
                    return this.$store.getters['session/vehicle'];
                }
            },
            clientName() {
              if (this.isLoggedIn) {
                  return this.appCustomer.firstName + ' ' + this.appCustomer.lastName;
              }
              return this.sessGuest.firstName + ' ' + this.sessGuest.lastName;
            },
            sessStore() {
                let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                if (typeof store === 'undefined') return '';
                return store.name;
            },
            sessGuest() {
                return this.$store.getters['session/guest'];
            },
            basket() {
                return this.$store.getters['session/basket'];
            }
        }

    }
</script>

<style scoped>

</style>
